<template>
    <ion-header class="ion-no-border lg:py-6 px-6" :style="headerCss">
        <ion-toolbar class="" :style="toolbarCss">
            <ion-buttons slot="start" class="flex-1">
                <ion-back-button v-show="showBackButton" :icon="caretBack" text="retour" :default-href="backDefaultHref"></ion-back-button>
            </ion-buttons>
            <ion-img v-if="showLogo" class="logo" src="assets/images/logo-hm.png" @click="goHome"></ion-img>
            <ion-buttons slot="end" v-show="showButtons" class="flex-1 ion-justify-content-end">
                <button-icon v-if="showHome" @click="goHome" icon-url="assets/images/home_icon.png">
                    Accueil
                </button-icon>
                <button-icon v-if="showAccount" icon-url="assets/images/account_icon.png" @click="goAccount">
                    Mon compte
                </button-icon>
                <button-icon v-if="showHelp" icon-url="assets/images/aide_icon.png">
                    Aide
                </button-icon>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
</template>

<script>
import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonImg, } from '@ionic/vue';
import { defineComponent } from 'vue';
import ButtonIcon from "@/components/ButtonIcon";
import { caretBack } from "ionicons/icons";
import { goToUserHouse } from "@/services/RoutingService";

export default defineComponent ({
    name: "NavBar",
    components: {
        ButtonIcon,
        IonHeader, IonToolbar, IonButtons, IonBackButton, IonImg,
    },
    props: {
        backDefaultHref: String,
        showBackButton: {
            type: Boolean,
            default: true,
        },
        showLogo: {
            type: Boolean,
            default: true,
        },
        showButtons: {
            type: Boolean,
            default: true,
        },
        showHelp: Boolean,
        showHome: Boolean,
        showAccount: Boolean,
        backgroundColor: {
            type: String,
            default: 'white' // white||transparent
        },
    },
    data() {
        return {
            caretBack,
        }
    },
    computed: {
        headerCss() {
            let css = {};
            if (this.backgroundColor === 'white') {
                css.backgroundColor = '#fff';

            }
            else if (this.backgroundColor === 'transparent') {
                css.backgroundColor = 'transparent';
                css.position = 'absolute';
                css.top = '0';
            }
            return css;
        },
        toolbarCss() {
            return {
                '--background': this.backgroundColor === 'white' ? '#fff' : 'transparent',
            }
        }
    },
    methods: {
        goAccount: function() {
            if (this.$route.params.house) {
                this.$router.push('/house/'+this.$route.params.house+'/account')
            }
            else {
                this.$router.push('/account');

            }
        },
        goHome: function() {
            goToUserHouse(this.$router, this.$store.getters.getUser, this.$route.params.house);
        }
    }
})
</script>

<style lang="scss" scoped>
ion-toolbar {
    --background: transparent;

    .logo {
        height: 80px;
    }

    ion-buttons {
        min-width: 90px;
    }

    .buttons-last-slot {
        ion-img {
            height: 100%;
        }
    }

    ion-back-button {
        --background: var(--ion-color-primary);
        --border-radius: 22px;
        --color: #fff;
        --padding-end: 22px;
        --padding-start: 12px;
        --icon-font-size: 22px;
        --icon-margin-end: 6px;
        font-weight: bold;
    }
}

</style>
