<template>
    <ion-app mode="ios">
        <ion-router-outlet/>
        <vue-easy-lightbox
            :visible="$store.getters.getLightBox.visible"
            :imgs="$store.getters.getLightBox.images"
            :index="$store.getters.getLightBox.index"
            :max-zoom="1.5"
            :min-zoom="1"
            :move-disabled="true"
            :loop="true"
            @hide="onLightboxHide"
        >
            <template v-slot:toolbar>

            </template>
        </vue-easy-lightbox>
        <ion-loading
            :is-open="$store.getters.getLoader"
            :duration="2000"
        >
        </ion-loading>
    </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, IonLoading } from '@ionic/vue';
import { defineComponent } from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox'

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet,
        IonLoading,
        VueEasyLightbox
    },
    mounted() {

    },
    methods: {
        onLightboxHide() {
            this.$store.commit('setLightBox', { visible:false, images: []});
        }
    }
});
</script>
