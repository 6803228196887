import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue'
import LoginPage from '../views/Login/LoginPage'

const routes = [

  {
    path: '',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/presentation',
    name: "presentation",
    component: () => import('@/views/PresentationPage.vue'),
  },
  {
    path: '/login',
    name: "login",
    component: LoginPage
  },
  {
    path: '/login/password',
    name: "login_password",
    component: () => import('@/views/Login/PasswordForgottenPage.vue'),
  },
  {
    path: '/login/password/reset',
    name: "login_password_reset",
    component: () => import('@/views/Login/PasswordResetPage.vue'),
  },
  {
    path: '/login/first',
    name: "login_first",
    component: () => import('@/views/Login/ChoiceFirstLoginPage.vue'),
  },
  {
    path: '/login/first-init',
    name: "login_first_init",
    component: () => import('@/views/Login/SetPhotoAndNamePage.vue'),
  },
  {
    path: '/login/has-house',
    name: "login_has_house",
    component: () => import('@/views/Login/ChoiceHasHousePage.vue'),
  },
  {
    path: '/login/no-house',
    name: "login_no_house",
    component: () => import('@/views/Login/ChoiceNoHousePage.vue'),
  },
  {
    path: '/login/houses',
    name: "login_choice_house",
    component: () => import('@/views/Login/ChoiceHousePage.vue'),
  },



  {
    path: '/registration',
    name: "registration",
    component: () => import('@/views/Registration/UserTypePage.vue'),
  },
  {
    path: '/registration/form',
    name: "registration_form",
    component: () => import('@/views/Registration/FormPage.vue'),
  },
  {
    path: '/registration/confirm',
    name: "registration_confirm",
    component: () => import('@/views/Registration/ConfirmationMailPage.vue'),
  },


  {
    path: '/subscription/choice',
    name: "subscription_choice",
    component: () => import('@/views/Subscription/ChoiceSubscriptionPage'),
  },
  {
    path: '/subscription/giftcard',
    name: "subscription_giftcard",
    component: () => import('@/views/Subscription/UseGiftCardPage'),
  },
  {
    path: '/subscription/duration',
    name: "subscription_duration",
    component: () => import('@/views/Subscription/ChoiceDurationPage'),
  },
  {
    path: '/subscription/engagement',
    name: "subscription_engagement",
    component: () => import('@/views/Subscription/EngagementPage'),
  },
  {
    path: '/subscription/resume',
    name: "subscription_resume",
    component: () => import('@/views/Subscription/ResumePage'),
  },
  {
    path: '/subscription/payment',
    name: "subscription_payment",
    component: () => import('@/views/Subscription/PaymentPage'),
  },



  {
    path: '/house/:house/login/first-init',
    name: "house_login_first_init",
    component: () => import('@/views/Login/SetPhotoAndNamePage.vue'),
  },
  {
    path: '/house/:house/account',
    name: "house_account",
    component: () => import('@/views/Account/AccountPage'),
  },
  {
    path: '/account',
    name: "account",
    component: () => import('@/views/Account/AccountPage'),
  },
  {
    path: '/house/:house/my-profile',
    name: "house_my_profile",
    component: () => import('@/views/Account/MyProfilePage'),
  },
  {
    path: '/account/my-profile',
    name: "my_profile",
    component: () => import('@/views/Account/MyProfilePage'),
  },
  {
    path: '/house/:house/family',
    name: "house_family",
    component: () => import('@/views/Account/FamilyPage'),
  },
  {
    path: '/account/invitations',
    name: "invitations",
    component: () => import('@/views/Account/InvitationsPage'),
  },


  {
    path: '/house/:house',
    name: "house",
    component: () => import('@/views/House/HousePage.vue'),
  },
  {
    path: '/house/:house/:room',
    name: "room",
    component: () => import('@/views/House/RoomPage.vue'),
  },
  {
    path: '/house/:house/:room/:theme',
    name: "theme",
    component: () => import('@/views/House/ThemePage.vue'),
  },
  {
    path: '/house/:house/:room/:theme/:category',
    name: "category",
    component: () => import('@/views/House/CategoryPage.vue'),
  },
  {
    //activity => ID activity default, not HouseActivity
    path: '/house/:house/:room/:theme/:category/:activity/list',
    name: "activities_list",
    component: () => import('@/views/House/ActivitiesListingPage.vue'),
  },
  {
    //activity => HouseActivity ID, not Activity ID default
    path: '/house/:house/:activity/read',
    name: "activity_read",
    component: () => import('@/views/House/ActivityReadPage.vue'),
  },
  {
    path: '/house/:house/:room/:theme/:category/:activity/:stage',
    name: "activity",
    component: () => import('@/views/House/ActivityPage.vue'),
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
