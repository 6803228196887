import { createStore } from 'vuex'

export default createStore({
    state: {
        loader: false,
        form: {},
        auth: {
            access_token: null,
            user: {
                id: null,
            },
        },
        notifications: {},
        lightBox: { images: [], visible: false, index: 0 },
        subscriptions: null,
        house: {},
        activity: {},
    },
    getters: {
        isHouseOwner(state) {
            if (state.house && state.house.owner) {
                return true;
            }
            return false;
        },
        getAuth(state) {
            return state.auth;
        },
        getUser(state) {
            return state.auth.user;
        },
        getAccessToken(state) {
            return state.auth.access_token;
        },
        getHouse(state) {
            return state.house;
        },
        getActivity(state) {
            return state.activity;
        },
        getLoader(state) {
            return state.loader;
        },
        getForm(state) {
            return state.form;
        },
        getSubscriptions(state) {
            return state.subscriptions;
        },
        getLightBox(state) {
            return state.lightBox;
        },
        getNotifications(state) {
            return state.notifications;
        }
    },
    mutations: {
        clearAuth(state) {
            state.auth = {
                access_token: null,
                user: {
                    id: null,
                }
            };
        },
        setAuth(state, data) {
            state.auth = { ...state.auth, ...data };
        },
        setUser(state, data) {
            state.auth.user = { ...state.auth.user, ...data };
        },
        setAccessToken(state, data) {
            state.auth.access_token = data;
        },
        setHouse(state, data) {
            state.house = { ...state.house, ...data };
        },
        clearHouse(state) {
            state.house = {};
        },
        setActivity(state, data) {
            state.activity = { ...state.activity, ...data };
        },
        setActivityData(state, data) {
            state.activity.data = { ...state.activity.data, ...data };
        },
        clearActivity(state) {
            state.activity = {};
        },
        setLoader(state, data) {
            state.loader = data;
        },
        setForm(state, data) {
            state.form = { ...state.form, ...data };
        },
        resetForm(state) {
            state.form = {};
        },
        setSubscriptions(state, data) {
            state.subscriptions = { ...state.subscriptions, ...data };
        },
        setLightBox(state, data) {
            state.lightBox = { ...state.lightBox, ...data };
        },
        setNotifications(state, data) {
            state.notifications = { ...state.notifications, ...data };
        },
        resetNotifications(state) {
            state.notifications = {};
        },
    },
    actions: {
        loadSubscriptions: function (context) {
            if (!context.state.subscriptions) {
                this.$axios.get('/subscriptions', { params: { no_loader: true } })
                    .then((response) => {
                        context.commit('setSubscriptions', response.data);
                    })
            }
        },
        openLightBox: function (context, { images, index }) {
            context.commit('setLightBox', {
                images: images,
                index: index,
                visible: true
            });
        },
    },
    modules: {}
})
