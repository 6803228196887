<template>

    <ion-button mode="ios" :class="{ 'uppercase' : uppercase, 'with-border': border }" :fill="fill" :strong="strong" :color="color" :disabled="disabled" :shape="shape" :expand="expand" :href="href" :size="size">
        <slot></slot>
    </ion-button>

</template>

<script>
import { IonButton } from '@ionic/vue';

export default {
    name: "ButtonIcon",
    components: {
        IonButton
    },
    props: {
        href: String,
        fill: String,
        color: String,
        disabled: Boolean,
        expand: String,
        size: String,
        shape: String,
        strong: Boolean,
        uppercase: Boolean,
        border: Boolean
    },
    computed: {

    }
}
</script>

<style lang="scss" scoped>
    ion-button {
        &.ion-color-tertiary {
            --color:var(--ion-color-primary);
        }
        &.button-strong {
            font-weight: bold;
        }
        &.button-large {
            --padding-end: 32px;
            --padding-start: 32px;
            --padding-bottom: 32px;
            --padding-top: 32px;
            min-width: 340px;
        }
        &.button-md {
            --padding-end: 12px;
            --padding-start: 12px;
            --padding-bottom: 12px;
            --padding-top: 12px;
            min-width: 180px;
        }
        &.button-multiple {
            --padding-end: 32px;
            --padding-start: 32px;
            --padding-bottom: 12px;
            --padding-top: 12px;
            font-size: 18px;
            height: auto;
            white-space: normal;
            &::part(native) {
                line-height: 1.4;
            }
        }

        &.with-border {
            &::part(native) {
                border: 3px solid #fff;
            }
        }
    }
</style>
