<template>
    <ion-page>
        <nav-bar background-color="white" back-default-href="/"></nav-bar>
        <ion-content :fullscreen="true">
            <div class="container h-full flex flex-col ion-justify-content-center">

                <form @submit.prevent="login" class="max-w-screen-md mx-auto w-full">

                    <ion-grid class="w-full">
                        <ion-row class="ion-align-items-center flex-1">
                            <ion-col size="5" class="ion-text-right">
                                <ion-label class="mr-2 font-bold">Mon adresse e-mail* :</ion-label>
                            </ion-col>
                            <ion-col>
                                <ion-input v-model="email" class="max-w-[350px] input-custom" required inputmode="email"
                                          autocomplete="email" type="email"></ion-input>
                            </ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center">
                            <ion-col size="5" class="ion-text-right">
                                <ion-label class="mr-2 font-bold">Mon mot de passe* :</ion-label>
                            </ion-col>
                            <ion-col>
                                <ion-input v-model="password" class="max-w-[350px] input-custom" required inputmode="password"
                                           type="password"></ion-input>
                            </ion-col>
                        </ion-row>
                        <ion-row class="mt-6">
                            <ion-col size="5" class="ion-text-right">
                                <ion-label class="mr-2 font-bold">Se souvenir de moi :</ion-label>
                            </ion-col>
                            <ion-col>
                                <ion-checkbox v-model="remember_me"></ion-checkbox>
                            </ion-col>
                        </ion-row>
                    </ion-grid>

                    <div class="ion-text-center mt-4">
                        <a @click="$router.push({ name: 'login_password' })" class="text-sm">J'ai perdu mon mot de passe ?</a>
                    </div>

                    <button-custom @click="login" class="mt-6" uppercase strong
                                   color="primary" size="large" shape="round"
                                   expand="block">
                        Je me connecte
                    </button-custom>
                    <button-custom @click="$router.push({ name: 'registration' })" class="mt-6" uppercase strong
                                   color="primary" fill="outline" size="large" shape="round"
                                   expand="block">
                        Je n'ai pas de compte
                    </button-custom>
                </form>
            </div>
        </ion-content>
        <ion-footer mode="ios">
            <div class="container py-8 ion-text-end">
                <a href="" class="text-sm">
                    La promesse Happy Madeleine : Nous ne communiquerons jamais votre e-mail - Voir notre charte
                </a>
            </div>
        </ion-footer>

    </ion-page>
</template>

<script>
import {
    IonContent,
    IonFooter,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonLabel,
    IonCheckbox
} from '@ionic/vue';
import { defineComponent } from 'vue';
import NavBar from "@/components/Navbar";
import ButtonCustom from "@/components/ButtonCustom";
import openToast from "@/services/ToastService";
import { showFirstError } from "@/services/FormatService";
import { goToUserHouse } from "@/services/RoutingService";

export default defineComponent({
    name: 'LoginPage',
    components: {
        NavBar,
        IonContent,
        IonFooter,
        IonPage,
        ButtonCustom,
        IonGrid, IonRow, IonCol,
        IonInput, IonLabel, IonCheckbox,
    },
    data() {
        return {
            email: null,
            password: null,
            remember_me: false,
        }
    },
    methods: {
        login() {
            this.axios.post('login', {
                email: this.email,
                password: this.password,
                remember_me: this.remember_me,
            })
                .then((response) => {
                    const user = response.data.user;
                    const access_token = response.data.access_token;
                    this.email = null;
                    this.password = null;

                    localStorage.setItem('access_token', access_token);

                    this.$store.commit('clearAuth');
                    this.$store.commit('setAuth', { access_token: access_token, user: user });

                    goToUserHouse(this.$router, user);
                })
                .catch(error => openToast(showFirstError(error?.response?.data?.errors), 'danger'));
        }
    }
});
</script>

<style scoped>

</style>
