import { alertController } from "@ionic/vue";

export async function alertBox(message, title, cssClass = 'primary', buttons = ['OK'], params = {}) {
    const alert = await alertController.create({
        cssClass: 'alert-simple '+cssClass,
        header: title,
        message: message,
        buttons: buttons,
        backdropDismiss: params.backdropDismiss ?? true,
        keyboardClose: params.keyboardClose ?? true,
    });
    await alert.present();

    return await alert.onDidDismiss();
}