import { toastController } from "@ionic/vue";

export default async function openToast(message, params = {}) {
    const toast = await toastController.create({
        message: message,
        duration: params.duration ?? 5000,
        color: params.color ?? 'danger',
    });
    return toast.present();
}
