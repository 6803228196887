import { API_URL } from "@/constants/UrlConstant";

export function goToUserHouse(router, user, houseId = null) {
    if (user.is_verified) {
        if (localStorage.getItem('invitation')) {
            localStorage.removeItem('invitation');
            router.push({ name: 'invitations' });
        }
        else {
            const myHousesIds = user.my_houses.map(value => value.id);
            if (houseId !== null && houseId > 0 && myHousesIds.includes(houseId)) {
                router.push({ name: 'house', params: { house: houseId } });
            }
            else if (myHousesIds.length === 1) {
                router.push({ name: 'house', params: { house: myHousesIds[0] } });
            }
            else if (myHousesIds.length > 1) {
                router.push({ name: 'login_choice_house' });
            }
            else {
                router.push({ name: 'login_first' });
            }
        }
    }
    else {
        router.push({ name: 'registration_confirm' });
    }
}

export function mediaUrl(url) {
    if (url) {
        return API_URL+'media/'+url+'?token='+localStorage.getItem('access_token');
    }
}
