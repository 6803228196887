<template>

    <div class="BubbleText" :class="size" >
        <ion-img class="persona" v-if="persona" :src="getPersonaImg"></ion-img>
        <div class="bubble">
            <div><slot></slot></div>
            <ion-img class="arrow" src="assets/images/bubble-arrow.png"></ion-img>
        </div>
    </div>

</template>
<script>
import { IonImg } from '@ionic/vue';

export default {
    name: "BubbleText",
    components: {
        IonImg
    },
    props: {
        persona: String, //boy,granny,both
        size: {
            type: String,
            default: 'md',
        }
    },
    computed: {
        getPersonaImg () {
            if (this.persona)
                return '/assets/persona/'+this.persona+'.png';

            return null;
        }
    }
}
</script>

<style lang="scss" scoped>

.BubbleText {
    @apply flex items-center;

    .persona {
        margin-top: 20px;
        margin-right:20px;
        height: 100px;
        @apply flex-none;

        &::part(image) {
            width: auto;
        }
    }
    .bubble {
        background-color: var(--ion-color-secondary);
        color: #FFFFFF;
        padding: 10px 24px;
        text-align: center;
        border-radius: 60px;
        min-height: 70px;
        max-height: 100px;
        position: relative;
        @apply flex-1 flex grow items-center justify-center text-xl w-full;

        .arrow {
            width: 30px; height: 30px;
            position: absolute;
            bottom: 5px;
            left: -8px;
        }
    }

    &.lg {
        .persona {
            height: 125px;
            margin-top: 0px;
            margin-right:40px;
        }
        .bubble {
            .arrow {
                width: 50px;
                height: 50px;
                bottom: 12px;
                left: -21px;
                transform: rotate(34deg);
            }
        }
    }
}
</style>
